<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5 pt-12">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c24 font-c7 w-4/5"
            >
              Ajouter un fournisseur
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">
            <div class="text-left text-c12">
              Type de fournisseur
            </div>
            <multiselect
              :option="optionType"
              :value-t="valueType"
              width="36%"
              background="white"
              class="mt-2"
              placeholder="Monture"
              @info="retourType"
            />

            <div class="text-left text-c12 mt-8">
              Intitulé
            </div>
            <inputo
              class="mt-2"
              placeholder="China la"
              @info="retourNom"
            />

            <div class="text-left text-c12 mt-8">
              Adresse
            </div>
            <inputo
              class="mt-2"
              placeholder="Chine"
              @info="retourAdresse"
            />

            <div class="text-left text-c12 mt-8">
              Téléphone
            </div>
            <div class="flex">
              <countrie
                class="mt-2 w-1/3"
                type="number"
                @info="retourCountrie"
              />
              <inputo
                class="mt-2 w-2/3 ml-2"
                type="number"
                @info="retourTelephone"
              />
            </div>


            <div>
              <div class="text-left text-c12 mt-8">
                Service
              </div>
              <inputo
                class="mt-2"
                placeholder="Vente d'accessoire de verre"
                @info="retourService"
              />
            </div>

            <div v-if="valueType === 'METIER'">
              <div class="text-left text-c12 mt-8">
                Délais de livraison (EN JOUR)
              </div>
              <inputo
                class="mt-2"
                type="number"
                placeholder="30"
                @info="retourLivraison"
              />
            </div>

            <div class="text-left text-c12 mt-8">
              Compte séquestre (Montant)
            </div>
            <inputo
              class="mt-2"
              type="number"
              placeholder="4000"
              @info="retourSequestre"
            />

            <div>
              <div class="text-left text-c12 mt-8">
                Compte fournisseur (Montant)
              </div>
              <inputo
                  class="mt-2"
                  type="number"
                  placeholder="4000"
                  @info="retourCompte"
              />

              <div v-if="valueType === 'METIER'">
                <div class="text-left text-c12 mt-8">
                  Catégorie
                </div>
                <multiselect
                    :option="optionCategorie"
                    :value-t="valueCategorie"
                    width="36%"
                    background="white"
                    class="mt-2"
                    @info="retourCategorie"
                />
              </div>
            </div>
            <div
              v-if="error !== null"
              class="mt-6 text-red text-c14"
            >
              {{ error }}
            </div>
          </div>

          <div class="flex">
            <div class="w-full">
              <buton
                label="Enregistrer"
                height="55px"
                radius="0px"
                :charge="charge"
                @oga="continuer"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import inputo from '../helper/form/input'
import multiselect from '../helper/form/multiselect'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"
import countrie from '../helper/form/countrie'

export default {
  name: 'Success',
  components: { PopupBase, buton, inputo, multiselect, countrie },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donne: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      donnes: null,
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer
      },
      optionType: ['GENERAL', 'METIER'],
      valueType: 'GENERAL',
      optionCategorie: ['Frabricant', 'Vendeur'],
      valueCategorie: 'Frabricant',
      telephone: '',
      nom: '',
      adresse: '',
      error: null,
      charge: false,
      sequestre: '',
      service: '',
      livraison: '',
      countrieCode: '',
      montant: ''
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
    this.valueType = this.type
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    continuer () {
      this.charge = true
      this.error = null
      if (this.nom.length > 0 && this.telephone.length > 0 && this.sequestre.length > 0 && this.countrieCode.length > 0) {
        let body = {
          name: this.nom,
          type: this.valueType,
          phone: this.countrieCode + this.telephone,
          location: this.adresse,
          service: this.service,
          meta: {
            categorie: null,
            sequestreAmount: this.sequestre
          },
          accountAmount: this.montant
        }

        if (this.valueType === 'METIER') {
          body.deliveryDelay = this.livraison.toString() + 'day'
          body.type = 'JOB'
          body.meta.categorie = this.valueCategorie
        }
        this.save(body)
      } else {
        this.charge = false
        this.error = 'Veuillez remplir les champs pour continuer'
      }
    },

    save (body) {
      http.post(apiroutes.baseURL + apiroutes.createSupplier, body)
          .then(response => {
            console.log(response)
            this.$emit('oga', false)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },

    retourNom (answer) {
      this.nom = answer
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    },

    retourCountrie (answer) {
      this.countrieCode = answer
      console.log(answer)
    },

    retourType (answer) {
      this.valueType = answer
    },

    retourAdresse (answer) {
      this.adresse = answer
    },

    retourMontant (answer) {
      this.montant = answer
    },

    retourTelephone (answer) {
      this.telephone = answer
    },

    retourSequestre (answer) {
      this.sequestre = answer
    },

    retourService (answer) {
      this.service = answer
    },

    retourLivraison (answer) {
      this.livraison = answer
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      height: 100%;
      margin: auto;
      border-radius: 12px;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
