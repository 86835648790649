<template>
  <div
    class="inputo p-1 text-c14"
    :class="{'error': error !== null,
             'success': success === true,
             'deseable': deseable === true}"
    :style="{'height': height, 'border': border, 'border-radius': radius, 'background-color': background}"
  >
    <div
      class="flex h-full items-center pl-3"
    >
      <div class="text-c20">
        {{ zone.map }}
      </div>
      <input
        v-model="value"
        type="text"
        class="w-full h-full pl-3 pr-3"
        :placeholder="placeholder"
      >
    </div>
    <selectCountrie
      v-if="value !== zone.callingCodes"
      :option="allCountrie"
      :value="value"
      class="mt-2"
      @info="retourSearch"
    />
    <!--    {{ sendValue }}-->
  </div>
</template>

<script>
import selectCountrie from './selectCountrie'
import pays from './pays.json'

export default {
  name: 'Index',
  components: {
    selectCountrie
  },

  props: {
    deseable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: '40px'
    },
    border: {
      type: String,
      default: '1px solid #DCDCE4'
    },
    radius: {
      type: String,
      default: '4px'
    },
    background: {
      type: String,
      default: 'white'
    }
  },

  data () {
    return {
      error: null,
      success: false,
      value: '',
      allCountrie: pays,
      selected: null,
      zone: '',
    }
  },

  created () {
    this.zone = this.allCountrie[0]
    this.value = this.zone.callingCodes
    this.$emit('info', this.value)
  },

  methods: {
    retourSearch (answer) {
      this.selected = answer
      this.zone = answer
      this.value = answer.callingCodes
      this.$emit('info', this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.inputo{
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  font-family: $font-default;
}
input{
  color: #0e0e0e;
  background-color: transparent;
}
.inputo:focus-within {
  border: 1px solid $hover-color!important;
}
.inputo:hover {
  border: 1px solid $hover-color!important;
}
.error{
  border: 1px solid #ea9f9f!important;
}
.success{
  border: 1px solid #5fc187!important;
}
.lab{
  background: #F2F2F2;
  border-radius: 10px;
  height: 35px;
  font-weight: bold;
  font-size: 14px;
  line-height: 27px;
  color: #979797;
}
.deseable{
  background: #eeeeee;
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .inputo{
    height: 65px;
  }
  input{
    font-size: 18px;
  }
}

@media screen and (max-width: 700px){
  .inputo {
    height: 55px;
  }
  input{
    font-size: 14px;
  }
}
</style>
