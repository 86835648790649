<template>
  <div class="rounded-10 h-auto bg-white w-full p-8">
    <div class=" w-full">
      <div
        class="w-full"
      >
        <steep-select
          v-if="steep.length > 0"
          :option="steep"
          :value="steepValue"
          @info="retourSteep"
        />
      </div>
    </div>

    <div class="mt-12">
      <div class="text-c18 text-left mt-12 mb-6">
        <span class="text-c34 font-c7 mr-2">{{ totalVerre }}</span> {{ steepValue }}
      </div>

      <genereaux v-if="steepValue === 'Fournisseur généraux'" @totalVerre="retourCount"/>
      <metier v-if="steepValue === 'Fournisseur métiers'" @totalVerre="retourCount"/>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import steepSelect from '../../helper/form/steepSelect'
import agenda from '../../../assets/icons/agenda.svg'
import down from '../../../assets/icons/down.svg'
import genereaux from './generauxTemplate'
import metier from './metierTemplate'

export default {
  name: "Index",
  components: {
    steepSelect,
    genereaux,
    metier
  },

  data () {
    return {
      icons: {
        arrow,
        agenda,
        down
      },
      steep: ['Fournisseur généraux', 'Fournisseur métiers'],
      steepValue: 'Fournisseur généraux',
      totalVerre: 0
    }
  },
  methods: {
    retourSteep(answer){
      this.steepValue = answer
      this.$emit('label', answer)
    },

    retourCount (answer) {
      this.totalVerre = answer
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.filtr{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36.39px;
}
.compFiltre{
  width: auto;
  min-width: 9.1%;
}
</style>
