<template>
  <div class="">
    <div class="w-full flex text-B text-c18 font-c4 text-left mb-10">
      <div class="w-3/6">
        INTITULÉ
      </div>
      <div class="w-1/5 ml-2">
        DÉLAI DE LIVRAISON
      </div>
      <div class="w-1/5 ml-6">
        COMPTE SÉQUESTRE
      </div>
      <div class="w-20 ml-6" />
    </div>

    <div
      v-if="charge"
      class="flex justify-center mt-16"
    >
      <easy-spinner
        type="dots"
        size="70"
      />
    </div>

    <div class="text-center text-c18 text-90 mt-12" v-if="allVerre.length === 0 && charge === false"> Aucun fournisseur enregistré dans cette catégorie</div>

    <div
      v-for="(item, index) in allVerre"
      :key="index"
      class="mt-4"
    >
      <div
        v-if="index > 0"
        class="item mt-4"
      />
      <liste
        class="mt-4"
        :item="item"
      />
    </div>
  </div>
</template>

<script>
import option from '../../../assets/icons/option.svg'
import liste from './metier'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'

export default {
  name: "Index",
  components: {
    liste
  },
  data () {
    return {
      icons: {
        option
      },
      allVerre: [],
      charge: true
    }
  },
  created () {
    this.allFournisseur()
  },

  methods: {
    allFournisseur(){
      http.get(apiroutes.baseURL + apiroutes.allSupplier + '?type=JOB')
          .then(response => {
            this.allVerre = response
            this.$emit('totalVerre', response.length)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item{
  border-top: 1px solid #DDDDDD;
  .icone {
    display: none;
  }
}
.item:hover{
  .icone {
    display: block;
  }
}
.all{
  min-height: 400px;
}
</style>
