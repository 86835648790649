<template>
  <div class="">
    <update-fournisor
      v-if="activeUpdate"
      :activation="activeUpdate"
      type="METIER"
      @oga="retourUpdate"
    />
    <div class="w-full flex text-c18 font-c4 text-left items-center item">
      <div class="w-3/6 flex items-center">
        <icon
          :data="icons.entreprise"
          height="50"
          width="50"
          class="cursor-pointer"
          original
        />
        <div class="ml-6">
          <div class="text-c18 font-c5">
            {{ item.name }}
          </div>

          <div class="text-c11 mt-2 text-60">
            <span class="text-90">{{ item.phone }}  | </span>

            <span class="ml-2"> {{ item.location }} </span>
          </div>
        </div>
      </div>

      <div class="w-1/5 ml-2 text-left">
        <span v-if="item.deliveryDelay !== '' && item.deliveryDelay !== null">{{ item.deliveryDelay.replace('day', '') }} jours</span>
      </div>

      <div class="w-1/5 ml-6 text-left">
        {{ item.balance.toLocaleString() }}<span class="text-90"> fcfa</span>
      </div>

      <div class="w-20 ml-6 text-left">
<!--        <icon-->
<!--          :data="icons.update"-->
<!--          height="20"-->
<!--          width="20"-->
<!--          class="cursor-pointer"-->
<!--          original-->
<!--          @click="activeUpdate = true"-->
<!--        />-->
      </div>
    </div>
  </div>
</template>

<script>
import entreprise from '../../../assets/icons/entreprise.svg'
import update from '../../../assets/icons/update.svg'
import updateFournisor from '../../popup/updateFournisor'

export default {
  name: "Index",
  components: {
    updateFournisor
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        entreprise,
        update
      },
      allVerre: ['', '', '', '', '', '', ''],
      position: false,
      activeUpdate: false
    }
  },
  methods: {
    activCheck(){
      this.position = ! this.position
    },
    retourUpdate (answer) {
      this.activeUpdate = answer
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
