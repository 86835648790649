<template>
  <div class="">
    <div class="w-full flex text-c18 font-c4 text-left items-center item">
      <div class="w-3/5 flex items-center">
        <icon
          :data="icons.entreprise"
          height="50"
          width="50"
          class="cursor-pointer"
          original
        />
        <div class="ml-6">
          <div class="text-c18 font-c5">
            {{ item.name }}
          </div>

          <div class="text-c11 mt-2 text-60">
            <span class="text-90">{{ item.phone }}  | </span>

            <span class="ml-2"> {{ item.location }} </span>
          </div>
        </div>
      </div>

      <div class="w-2/5 ml-2 text-left">
        {{ item.services }}
      </div>
    </div>
  </div>
</template>

<script>
import entreprise from '../../../assets/icons/entreprise.svg'

export default {
  name: "Index",
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        entreprise
      },
      allVerre: ['', '', '', '', '', '', ''],
      position: false
    }
  },
  methods: {
    activCheck(){
      this.position = ! this.position
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}

.item:hover{
 border-left: 3px groove black;
  padding-left: 30px;
}
</style>
