<template>
  <div
    class="defile"
    :style="{'font-size': size}"
  >
    <div
      v-for="(item, index) in donnee"
      :key="index"
      class="cursor-pointer item"
    >
      <div
        class="pt-4 pb-4 text-left pl-4 pr-4"
        @click="selectItem(item)"
      >
        {{ item.name }}
      </div>
      <divider
        v-if="index < option.length-1"
        height="0.5px"
        color="#DDDDDD"
      />
    </div>
    <div
      v-if="donnee.length === 0"
      class="cursor-pointer"
    >
      <div class="md:p-5 md:pt-5 md:pb-5 pt-1 pb-1 pl-5 pr-5 grayColor">
        Recherche introuvable
      </div>
    </div>
  </div>
</template>

<script>
import divider from '../add/divider'

export default {
  name: 'Index',
  components: {
    divider
  },

  props: {
    option: {
      type: Array,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: '18px'
    }
  },

  data () {
    return {
    }
  },

  computed: {
    donnee: function () {
      let retour = this.option
      if (this.value !== null) {
        let authorNameSearchString = this.value
        authorNameSearchString = authorNameSearchString.toLowerCase()
        retour = retour.filter((item) => {
          if (item.name !== undefined && (item.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
            return item
          }
        })
      }
      return retour
    }
  },

  methods: {
    selectItem (item) {
      this.$emit('info', item)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables";
.defile{
  background: #FFFFFF;
  border: 0.5px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 12px;
  font-style: normal;
  line-height: 21px;
  color: #000000;
  font-family: $font-default;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 999;
  position: absolute;
}
.defile::-webkit-scrollbar {
  display: none;
}
.item:hover{
  background-color: $hover-side;
}
.grayColor{
  color: #c3c3c3;
}
@media screen and (max-width: 700px) {
  .defile{
    font-size: 17px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 770px){
  .defile{
    font-size: 18px;
  }
}
@media screen and (max-width: 769px) and (min-width: 701px){
  .defile{
    font-size: 16px;
  }
}
</style>
