<template>
  <div class="p-10 pr-16">
    <newFournisor
      v-if="activeFournisor"
      :activation="activeFournisor"
      :type="type"
      @oga="retourFournisor"
    />
    <div
      class="flex items-center"
    >
      <div class="w-2/3 flex text-left text-c48 items-center font-c6">
        <icon
          :data="icons.back"
          height="70"
          width="70"
          class="mr-2 cursor-pointer"
          original
        />
        <div class="ml-4">
          Fournisseurs
        </div>
      </div>

      <div class="w-1/3">
        <div class="flex justify-end">
          <div class="w-4/6">
            <bouton
              :label="buttonLabel"
              radius="5.4px"
              weight="600"
              size="17px"
              @oga="retourClick"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6">
      <global-view @label="retourEtape" />
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import bouton from '../../component/helper/add/button'
import globalView from '../../component/cdevs/fournisseur/globalData'
import newFournisor from '../../component/popup/newFournisor'

export default {
  name: "Index",

  components: {
    bouton,
    globalView,
    newFournisor
  },

  data () {
    return {
      icons: {
        back
      },
      buttonLabel: 'Ajouter un fournisseur',
      etape: 'Fournisseur généraux',
      activeAdd: false,
      activeFournisor: false,
      type: 'GENERAL'
    }
  },
  mounted() {
  },
  methods: {
    retourClick (answer) {
      this.activeFournisor = true
    },

    retourBack (answer) {
      this.activeAdd = answer
    },

    retourEtape (answer) {
      this.etape = answer
      if (answer === 'Fournisseur généraux'){
        this.type = 'GENERAL'
      } else {
        this.type = 'METIER'
      }
    },

    retourFournisor (answer) {
      this.activeFournisor = answer
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
</style>
